<!--
 * @Author: Link
 * @Date: 2021-07-15 16:58:15
 * @LastEditTime: 2021-07-17 09:46:13
-->
<template>
  <div class="notice">
    <img src="@/assets/notice.png" alt="" />
  </div>
</template>

<script>
export default {}
</script>

<style lang='less' scoped>
.notice {
  width: 100%;
  overflow: hidden;
  min-height: 100vh;
  background: #e61f18;
  img {
    width: 100%;
    height: auto;
  }
}
</style>